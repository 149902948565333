import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {enMessagesCams} from './en.js';
import {ruMessagesCams} from './ru.js';
import {trMessagesCams} from './tr.js';
import {enMessages} from 'camsng-frontend-shared/i18n/en.js';
import {ruMessages} from 'camsng-frontend-shared/i18n/ru.js';
import {trMessages} from 'camsng-frontend-shared/i18n/tr.js';
// Подключаем VueI18n
Vue.use(VueI18n);
// Настройка сообщений для всех поддерживаемых языков, подключение переводов из shared
const messages = {
  en: {...enMessagesCams, ...enMessages},
  ru: {...ruMessagesCams, ...ruMessages},
  tr: {...trMessagesCams, ...trMessages},
};
// Инициализация i18n с настройками
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || localStorage.setItem('locale', 'ru'), // Язык по умолчанию
  messages,
});
export default i18n;
